import React, { Component } from 'react';
import './contact.css'; // Create a CSS file for styling

class ContactForm extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      email: '',
      message: '',
      successMessage: '',
      errorMessage: '',
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message } = this.state;

    try {
      // Make a POST request to your backend to send the email
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, message }),
      });

      if (response.ok) {
        this.setState({
          successMessage: 'Email sent successfully!',
          errorMessage: '',
          name: '',
          email: '',
          message: '',
        });
      } else {
        this.setState({
          errorMessage: 'Failed to send email. Please try again later.',
          successMessage: '',
        });
      }
    } catch (error) {
      console.error(error);
      this.setState({
        errorMessage: 'An error occurred. Please try again later.',
        successMessage: '',
      });
    }
  };

  render() {
    const { name, email, message, successMessage, errorMessage } = this.state;

    return (
      <div className="contact-form-container">
        <h2>Contact Us</h2>
        {successMessage && <div className="success-message">{successMessage}</div>}
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="name"
              value={name}
              onChange={this.handleChange}
              placeholder="Your Name"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              placeholder="Your Email"
              required
            />
          </div>
          <div className="form-group">
            <textarea
              name="message"
              value={message}
              onChange={this.handleChange}
              placeholder="Your Message"
              required
            />
          </div>
          <div className="form-group">
            <button type="submit">Send</button>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
