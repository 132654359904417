import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'; // Import your CSS styles here
import Tools from './Tools';
import FavoriteTools from './FavoriteTools';
import SubmitTool from './SubmitTool';
import News from './News';
import Contact from './Contact';
import ToolDetail from './ToolDetail';
import Navbar from './Navbar'; // Import your Navbar component
import Admin from './Admin/Admin'

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/*" element={<WithNavbar />}/>
        </Routes>
      </div>
    </Router>
  );
}


function WithNavbar() {
  return (
    <>
      <Navbar />
      <main>
        <Routes>
          <Route path="/" element={<Tools />} />
          <Route path="/favorites" element={<FavoriteTools />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/tools/:toolName" element={<ToolDetail />} />
          <Route path="/submit" element={<SubmitTool />} />
        </Routes>
      </main>
    </>
  );
}

export default App;
