import React, { useEffect, useState } from 'react';
import { AiFillUnlock, AiTwotoneStar } from 'react-icons/ai';
import { CiBookmarkCheck } from 'react-icons/ci';
import { HiBadgeCheck } from 'react-icons/hi';
import { BsStars } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { RxExternalLink } from 'react-icons/rx';
import axios from 'axios';
import './ToolDetails.css';
import { useParams } from 'react-router-dom';

// Define the API URL
const apiUrl = 'https://api.futurai.net/tools/';

function ToolDetail() {

  const [tool, setTool] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isBookmarked, setIsBookmarked] = useState(false);
  const [favoriteTools, setFavoriteTools] = useState([]);
  // Access the dynamic parameter (toolName) from the URL
  const { toolName } = useParams();





  useEffect(() => {

     // Define the API URL
     const apiUrl = `https://api.futurai.net/tools/${toolName}`;

     // Make the API request
     axios
     .get(apiUrl)
     .then((response) => {
       setTool(response.data);
       setLoading(false);
     })
     .catch((err) => {
       setError(err);
       setLoading(false);
     });

     
 

    // Retrieve favorite tools from localStorage
    const storedArrayString = localStorage.getItem('myFavoriteTools');
    const storedFavorites = JSON.parse(storedArrayString) || [];
    setFavoriteTools(storedFavorites);
    
    // Check if the current tool is in the favorites list
    // const isCurrentlyBookmarked = storedFavorites.some((favTool) => favTool.id === tool.id);
    // setIsBookmarked(isCurrentlyBookmarked);
    }, []);

    if (loading) {
      return <div className="tool-details">Loading...</div>;
    }
  
    if (error) {
      return <div className="tool-details error">Error: {error.message}</div>;
    }
  
    if (!tool) {
      return <div className="tool-details">No data available</div>;
    }
    
    const updateLocalStorage = (newFavorites) => {
    // Update localStorage with the new list of favorite tools
    localStorage.setItem('myFavoriteTools', JSON.stringify(newFavorites));
    };
    
    const handleBookmarkClick = () => {
    const updatedFavorites = [...favoriteTools];
    
    // if (isBookmarked) {
    //   // Remove the tool from the array of favorites
    //   const toolIndex = updatedFavorites.findIndex((favTool) => favTool.id === tool.id);
    //   if (toolIndex !== -1) {
    //     updatedFavorites.splice(toolIndex, 1);
    //     updateLocalStorage(updatedFavorites);
    //     setIsBookmarked(false);
    //   }
    // } else {
    //   // Add the tool to the array of favorites
    //   updatedFavorites.push(tool);
    //   updateLocalStorage(updatedFavorites);
    //   setIsBookmarked(true);
    // }
    };
    

  return (

    <div className='container'>
    <div className='tool-details' >
        <div className='tool-details-image'>
          <img src={tool.image} alt={tool.title} />
        </div>
        {tool.is_premium ? <span className='badge'>$ {tool.price}/mo</span> : ''}
        <div className='header'>
          <h3 className='tool-link' >
            {tool.title} - {<span className='tagline'>{tool.tagline}</span>} {tool.is_verified === 1 ? <HiBadgeCheck /> : ''}
          </h3>
          
          <span>
            <CiBookmarkCheck />
          </span>
        </div>
        <div className='description'>
          <p>
            {tool.description}
          </p>
        </div>
        <div className='price'>
          <button>
            <AiFillUnlock /> {tool.is_premium ? 'Paid' : 'Free'}
          </button>
          <span>
            <BsStars /> <p>1</p>
          </span>
        </div>
        <div className='tags'>
          <p>{tool.tags}</p>
        </div>
        <div className='btns'>
          <button onClick={() => window.open(tool.website_link, '_blank')}>
            <RxExternalLink />
          </button>
          <button onClick={handleBookmarkClick}>
            {isBookmarked ? <FaCheck /> : <CiBookmarkCheck />}
          </button>
        </div>
      </div>
    </div>








    // <div className="tool-details">
    //   <div className="image">
    //     {tool.image && <img src={tool.image} alt={tool.title} />}
    //   </div>
    //   <div className="header">
    //     <h1 className="tool-title">{tool.title}</h1>
    //     {tool.is_verified === 1 && (
    //       <span className="verification-badge">Verified</span>
    //     )}
    //   </div>
    //   <div className="description">
    //     <p>{tool.description}</p>
    //   </div>
    //   <div className="details">
    //     <div className="price">
    //       {tool.is_premium ? (
    //         <span className="premium-price">${tool.price}/mo</span>
    //       ) : (
    //         <span className="free-price">Free</span>
    //       )}
    //     </div>
    //     <div className="tags">
    //       <p>{tool.tags}</p>
    //     </div>
    //   </div>
    //   <div className="actions">
    //     <a
    //       href={tool.website_link}
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       className="visit-website-button"
    //     >
    //       Visit Website
    //     </a>
    //     <button className="bookmark-button">Bookmark</button>
    //   </div>
    // </div>
  );
}
export default ToolDetail;
