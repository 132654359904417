import React, { useState, useEffect } from 'react';
import './submittool.css'

const initialFormData = {
  title: '',
  tagline: '',
  is_premium: '1',
  price: '',
  category_id: '',
  website_link: '',
  description: '',
  tags: '',
  image: null,
};


function SubmitTool() {

  const [isLoading, setIsLoading] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);


  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState(initialFormData);

  useEffect(() => {
    // Fetch tool categories from the API
      // Define the options for the fetch request
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
    fetch('https://api.futurai.net/tools_categories',requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // Assuming the API response is an array of category objects like [{"id":3,"name":"Food"},{"id":4,"name":"Real Estate"}]
        setCategories(data);
      })
      .catch((error) => {
        console.error('Error fetching tool categories:', error);
      });
  }, []);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      image: file,
    });
  };


  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   console.log(JSON.stringify(formData))
  //   try {
  //     const response = await fetch('https://api.futurai.net/tools', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json', // Set the Content-Type to JSON
  //       },
  //       body: JSON.stringify(formData), // Convert formData to JSON
  //     });
      
      
  
  //     if (response.ok) {
  //       // Tool was successfully created
  //       // You can handle the response here if needed
  //       console.log('Tool created successfully');
  //     } else {
  //       console.error('Error creating tool');
  //     }
  //   } catch (error) {
  //     console.error('Error creating tool:', error);
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    // Create a FormData object to send the data including the image
    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    try {
      const response = await fetch('https://api.futurai.net/tools', {
        method: 'POST',
        body: formDataToSend,
      });

      if (response.ok) {
        setToastMessage('Tool submitted successfully');
        setFormData(initialFormData);
      } else {
        setToastMessage('Error creating tool');
      }
    } catch (error) {
      console.error('Error creating tool:', error);
      setToastMessage('Error creating tool: ' + error.message);
    }
    setIsLoading(false);
    setTimeout(() => setToastMessage(null), 5000);

  };


  return(
    <div className='submit-tool'>
    <div className='add-tool'>
      <h3>Submit Tool</h3>

      <form onSubmit={handleSubmit}>
        <div>

          <div class="form-group">
            <label>Title</label>
            <input type="text" name="title" value={formData.title} onChange={handleInputChange} required/>
          </div>

          <div className="form-group">
            <label htmlFor="tagLine">Tag Line</label>
            <input type="text" id="tagLine" name="tagline" value={formData.tagline} onChange={handleInputChange} required />
          </div>


          {/* <div className="form-group">
            <label htmlFor="premium">Premium</label>
            <select id="premium" name="is_premium" required value={formData.is_premium} onChange={handleInputChange}>
              <option value="1">Paid</option>
              <option value="0">Free</option>
            </select>
          </div> */}

        <div className="form-group">
          <label htmlFor="premium">Premium</label>
          <select id="premium" name="is_premium" required value={formData.is_premium} onChange={handleInputChange}>
            <option value="" disabled hidden>Select an option</option>
            <option value="1">Paid</option>
            <option value="0">Free</option>
          </select>
        </div>



          <div className='container-devider'>

          <div className="form-group">
            <label htmlFor="price">Price</label>
            <input type="number" id="price" name="price" value={formData.price} onChange={handleInputChange} required />
          </div>
          
          {/* <div className="form-group">
            <label htmlFor="category">Category</label>
            <select id="category" name="category_id" required value={formData.category_id} onChange={handleInputChange}> */}
               {/* Map over the categories and populate the <option> elements */}
               {/* {categories.map((category) => (
                <option key={category.id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </select>
          </div> */}

          <div className="form-group">
            <label htmlFor="category">Category</label>
            <select id="category" name="category_id" required value={formData.category_id} onChange={handleInputChange}>
              <option value="" disabled hidden>Select a category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>

          </div>


          <div className="form-group">
            <label htmlFor="website">Website</label>
            <input type="text" id="website" name="website_link" value={formData.website_link} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="description">Description</label>
            <textarea id="description" name="description" rows="4" value={formData.description} onChange={handleInputChange} required></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="tags">Tags</label>
            <input type="text" id="tags" name="tags" value={formData.tags} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="image">Image</label>
            <input type="file" id="image" name="image" accept="image/*" onChange={handleImageChange} required />
          </div>
          <div className="form-group">
            <button type="submit" className="btn">Submit</button>
          </div>

          
        </div>
      </form>

    </div>

    {isLoading && (
      <div className='loading-overlay'>
        <div className='spinner'></div>
      </div>
    )}
    {toastMessage && (
      <div className='toast-message'>
        {toastMessage}
      </div>
    )}
  </div>
  )
}
export default SubmitTool;