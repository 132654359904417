import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './navbar.css';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

    // Function to close the navbar when a link is clicked
    const closeNavbar = () => {
      setIsMenuOpen(false);
    };

  return (
    <div className={`navbar ${isMenuOpen ? 'open' : ''}`}>
      <button className="toggle-button" onClick={toggleMenu}>
        {isMenuOpen ? '✕' : '☰'}
      </button>
      <nav className={`nav-menu ${isMenuOpen ? 'open' : ''}`}>
        <ul>
          <li className="nav-item">
            <Link to="/" onClick={closeNavbar}>Home</Link>
          </li>
          <li className="nav-item">
            <Link to="/favorites" onClick={closeNavbar}>Favorites</Link>
          </li>
          <li className="nav-item">
            <Link to="/submit" onClick={closeNavbar}>Submit Tool</Link>
          </li>
          <li className="nav-item">
            <Link to="/news" onClick={closeNavbar}>News</Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" onClick={closeNavbar}>Contact</Link>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
