import React, { useEffect, useState, useRef } from 'react';  
import NewsItem from './NewsItem';
import './news.css'

const API_URL = 'https://api.futurai.net/news';

const News = ()=>{

    const [news, setNews] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const containerRef = useRef(null);  // Create a ref for the outermost container
    const [isLoading, setIsLoading] = useState(false);

    const getNews = async () => {
        setIsLoading(true);
        const URL = `${API_URL}?page=${pageNumber}`;

        try {
            const response = await fetch(URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();

            if (pageNumber === 1) {
                setNews(data); // For the first page, replace tools
            } else {
                setNews(prevNews => [...prevNews, ...data]); // For subsequent pages, append to existing tools
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
    }

    const handleScroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500) {
            setPageNumber(prev => prev + 1);
        }
    };

    useEffect(() => {
        getNews();
        
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pageNumber]);

    
return(
    <div className="news">
        

        {news?.length > 0 ? (
                <div className="container">
                    
                    {news.map((newsItem) => <NewsItem key={newsItem.id} newsItem={newsItem} />)}
                </div>
            ) : (
                <div className="empty">
                    <h2>No news found</h2>
                </div>
            )}

            {isLoading && (
                <div className="loader"></div>
            )}

    </div>
)
}

export default News;