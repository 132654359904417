import "./favoritetools.css"
import {useEffect, useState} from "react";
import SearchIcon from './search.svg';
import Card from "./Card";


function Tools(){

    // const [tools,setFavoriteTools] = useState([])
    const [favoriteTools, setFavoriteTools] = useState([]);
    // const [pageNumber,setPageNumber] = useState(1);


    

    useEffect(()=>{
        const storedArrayString = localStorage.getItem('myFavoriteTools');
        const storedFavorites = JSON.parse(storedArrayString) || [];
        setFavoriteTools(storedFavorites);
    },[])


  
    return(
        <div className="tools">
             <h1>Favorite Tools</h1>
             

             {
                favoriteTools?.length > 0
                ? (
                    <div className="container">
                        {favoriteTools.map((tool)=> (
                            // <MovieCard key={movie.title} movie={movie} />
                            <Card key={tool.id} tool={tool}/>
                        ))}
                    </div>
                ) : (
                    <div className="empty">
                        <h2>No tools found</h2>
                    </div>
                )}

        </div>
    )
}

export default Tools;