import React, { Component } from 'react';
import './adminlogin.css'; // Import your CSS styles here

class AdminLogin extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      errorMessage: '',
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;

    try {
      // Make a POST request to your backend to authenticate the admin
      // Replace '/admin-authenticate' with the actual endpoint for admin authentication
      const response = await fetch('https://api.futurai.net/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        // Admin authenticated successfully
        // Redirect to the admin panel or perform the necessary action
        localStorage.setItem('login',response.token);
        
      } else {
        // Authentication failed
        this.setState({ errorMessage: 'Invalid credentials. Please try again.' });
      }
    } catch (error) {
      console.error(error);
      this.setState({ errorMessage: 'An error occurred. Please try again later.' });
    }
  };

  render() {
    const { email, password, errorMessage } = this.state;

    return (
      <div className="admin-login-container">
        
        <h2>Admin Login</h2>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <form onSubmit={this.handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              name="email"
              value={email}
              onChange={this.handleChange}
              placeholder="email"
              required
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              name="password"
              value={password}
              onChange={this.handleChange}
              placeholder="Password"
              required
            />
          </div>
          <div className="form-group">
            <button type="submit">Login</button>
          </div>
        </form>


        
      </div>
    );
  }
}

export default AdminLogin;
