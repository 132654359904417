import React, { useEffect, useState } from 'react';
import './Card.css';
import { AiFillUnlock, AiTwotoneStar } from 'react-icons/ai';
import { CiBookmarkCheck } from 'react-icons/ci';
import { HiBadgeCheck } from 'react-icons/hi';
import { BsStars } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import { RxExternalLink } from 'react-icons/rx';

function Card({ tool }) {
//   const [isBookmarked, setIsBookmarked] = useState(false);

//   const handleBookmarkClick = () => {
//     if (isBookmarked) {
//       localStorage.removeItem(tool.id);
//     } else {
//       localStorage.setItem(tool.id, 'true');
//     }
//     setIsBookmarked(!isBookmarked);
//   };

//   useEffect(() => {
//     const storedValue = localStorage.getItem(tool.id);
//     setIsBookmarked(storedValue === 'true');
//   }, [tool.id]);

const [isBookmarked, setIsBookmarked] = useState(false);
const [favoriteTools, setFavoriteTools] = useState([]);

  useEffect(() => {
// Retrieve favorite tools from localStorage
const storedArrayString = localStorage.getItem('myFavoriteTools');
const storedFavorites = JSON.parse(storedArrayString) || [];
setFavoriteTools(storedFavorites);

// Check if the current tool is in the favorites list
const isCurrentlyBookmarked = storedFavorites.some((favTool) => favTool.id === tool.id);
setIsBookmarked(isCurrentlyBookmarked);
}, [tool.id]);

const updateLocalStorage = (newFavorites) => {
// Update localStorage with the new list of favorite tools
localStorage.setItem('myFavoriteTools', JSON.stringify(newFavorites));
};

const handleBookmarkClick = () => {
const updatedFavorites = [...favoriteTools];

if (isBookmarked) {
  // Remove the tool from the array of favorites
  const toolIndex = updatedFavorites.findIndex((favTool) => favTool.id === tool.id);
  if (toolIndex !== -1) {
    updatedFavorites.splice(toolIndex, 1);
    updateLocalStorage(updatedFavorites);
    setIsBookmarked(false);
  }
} else {
  // Add the tool to the array of favorites
  updatedFavorites.push(tool);
  updateLocalStorage(updatedFavorites);
  setIsBookmarked(true);
}
};

function stringToUrl(){
    const delimiter = "-";
    const concatenatedString = tool.title.split(" ").join(delimiter);
    if(concatenatedString){
      // window.location.href = concatenatedString;
        window.open('/tools/'+concatenatedString, '_blank'); // Opens the URL in a new tab or window
    }

  }


  return (
    <div className='card' >
      <div className='image'>
        <img src={tool.image} alt={tool.title} />
      </div>
      {tool.is_premium ? <span className='badge'>$ {tool.price}/mo</span> : ''}
      <div className='header'>
        <h3 className='tool-link' onClick={stringToUrl}>
          {tool.title} {tool.is_verified === 1 ? <HiBadgeCheck /> : ''}
        </h3>
        <span>
          <CiBookmarkCheck />
        </span>
      </div>
      <div className='description'>
        <p>
          {tool.description.length > 70
            ? `${tool.description.substring(0, 70)}...`
            : tool.description}
        </p>
      </div>
      <div className='price'>
        <button>
          <AiFillUnlock /> {tool.is_premium ? 'Paid' : 'Free'}
        </button>
        <span>
          <BsStars /> <p>1</p>
        </span>
      </div>
      <div className='tags'>
        <p>{tool.tags}</p>
      </div>
      <div className='btns'>
        <button onClick={() => window.open(tool.website_link, '_blank')}>
          <RxExternalLink />
        </button>
        <button onClick={handleBookmarkClick}>
          {isBookmarked ? <FaCheck /> : <CiBookmarkCheck />}
        </button>
      </div>
    </div>
  );
}

export default Card;
