import AdminLogin from "./AdminLogin"

function Admin(){

return(
    
    <AdminLogin/>
)
}


export default Admin