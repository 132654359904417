import React, { useEffect, useState, useRef } from 'react';  
import "./Tools.css"
import SearchIcon from './search.svg';
import Card from "./Card";

const API_URL = 'https://api.futurai.net/tools';

function Tools() {
    const [tools, setTools] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const containerRef = useRef(null);  // Create a ref for the outermost container
    const [isLoading, setIsLoading] = useState(false);


    const checkScrollEnd = () => {
        if (containerRef.current) {
            const { scrollHeight, scrollTop, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 100) {
                setPageNumber(prev => prev + 1);
            }
        }
    };

    const searchTools = async () => {
        setIsLoading(true);
        const URL = `${API_URL}?name=${searchTerm}&page=${pageNumber}`;

        try {
            const response = await fetch(URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            const data = await response.json();

            if (pageNumber === 1) {
                setTools(data); // For the first page, replace tools
            } else {
                setTools(prevTools => [...prevTools, ...data]); // For subsequent pages, append to existing tools
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
        setIsLoading(false);
    }

    const handleScroll = () => {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500) {
            setPageNumber(prev => prev + 1);
        }
    };

    useEffect(() => {
        searchTools();
        
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [searchTerm, pageNumber]);

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
        setPageNumber(1);  // Reset pageNumber
        setTools([]);      // Clear existing tools for a fresh search
    }


    return (
        <div className="tools">
            <h1>Futur Ai</h1>
            <div className="search">
                <input 
                    placeholder="Search AI Tools"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                />
                <img 
                    src={SearchIcon}
                    alt="search"
                    onClick={() => {
                        setPageNumber(1); // Reset pageNumber for a new search
                        searchTools();
                    }}
                />
            </div>

            {tools?.length > 0 ? (
                <div className="container">
                    {tools.map((tool) => <Card key={tool.id} tool={tool} />)}
                </div>
            ) : (
                <div className="empty">
                    <h2>No tools found</h2>
                </div>
            )}

            {isLoading && (
                <div className="loader"></div>
            )}


        </div>
    );
}

export default Tools;
