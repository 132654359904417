import './newsitem.css'

function getTimeDifference(inputDate) {
    // Parse the input date string to a Date object
    const inputDateTime = new Date(inputDate);
  
    // Get the current date and time
    const currentDate = new Date();
  
    // Calculate the time difference in milliseconds
    const timeDifference = currentDate - inputDateTime;
  
    // Calculate the difference in years, months, and days
    const yearsDifference = currentDate.getFullYear() - inputDateTime.getFullYear();
    const monthsDifference = currentDate.getMonth() - inputDateTime.getMonth();
    const daysDifference = currentDate.getDate() - inputDateTime.getDate();
  
    // If the difference is equal to or greater than 30 days, return the difference in months
    if (timeDifference >= 30 * 24 * 60 * 60 * 1000) {
      if (monthsDifference < 0) {
        // Adjust for negative months difference
        return yearsDifference * 12 + (12 + monthsDifference) + ' months';
      } else {
        return yearsDifference * 12 + monthsDifference + ' months';
      }
    }
    // If the difference is equal to or greater than 365 days, return the difference in years
    else if (timeDifference >= 365 * 24 * 60 * 60 * 1000) {
      if (daysDifference < 0) {
        // Adjust for negative days difference
        return yearsDifference + (12 / currentDate.getMonth()) + ' years';
      } else {
        return yearsDifference + ' years';
      }
    }
    // Otherwise, return the difference in days
    else {
      return daysDifference + ' days';
    }
  }

const NewsItem = ({newsItem})=>{

    return(
        <div className="news-item">

            <div className="inner-container-top">
            <p onClick={()=>{window.open(newsItem.news_link, '_blank')}} className='news-title'>{newsItem.title} <a className='news-link'>{newsItem.news_link.substring(0,70)}...</a></p> 
            
            </div>
            

            <div className="inner-container-news">

                <p className="published-date">{getTimeDifference(newsItem.created_at)} ago</p>

                <div className="category">{newsItem.category_name}</div>
            </div>

        </div>
    )
}

export default NewsItem;